import React from "react";
import { useStaticQuery } from "gatsby";
import RichTextRenderer from "../utils/richTextRenderer";

import PageHeader from "../components/pageHeader";
import Layout from "../components/layout";
import SEO from "../components/seo";

const CyfhInfo = ({ location }) => {
  const { cyfhInfo } = useStaticQuery(
    graphql`
      query {
        cyfhInfo: contentfulCyFhInfoPage {
          content {
            json
          }
        }
      }
    `
  );

  return (
    <Layout pageTitle="cyfh-info">
      <SEO pageTitle="Τι σημαίνει;" />
      <PageHeader
        pageTitle="ΟΙΚΟΓΕΝΗΣ ΥΠΕΡΧΟΛΗΣΤΕΡΟΛΑΙΜΙΑ: ΤΙ ΣΗΜΑΙΝΕΙ;"
        pageDescription="Μάθε τι σημαίνει, μπορεί να σε αφορά!"
        page="Οικογενής Υπερχοληστερολαιμία: Τι σημαίνει;"
        location={location}
        isCyFH={true}
      />

      <div className="page-wrapper">
        <RichTextRenderer richTextJson={cyfhInfo.content.json} />
      </div>
    </Layout>
  );
};

export default CyfhInfo;
