import React from "react";
import Breadcrumb from "./Breadcrumb";

const PageHeader = ({ location, page, pageTitle, pageDescription, isCyFH }) => {
  return (
    <div className="page-header">
      <div className="header-wrapper">
        <Breadcrumb location={page} link={location.pathname} isCyFH={isCyFH} />
        <div className="text">
          <h1>{pageTitle}</h1>
          <p>{pageDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
